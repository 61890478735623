<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Dosen</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Dosen</span>
            </div>
        </div> 
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Dosen
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <input v-model="form.id" type="hidden">
                                    <label class="col-6">NIP</label>
                                    <label class="col-6">NIDN</label>
                                    <div class="col-6">
                                        <input type="number" v-model="form.nip" value="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-6">
                                        <input type="number" v-model="form.nidn" value="" class="form-control" placeholder="" >
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-8">Nama Lengkap & Gelar<span class="text-danger">*</span></label>
                                    <label class="col-4">Jenis Kepegawaian</label>
                                    <div class="col-8">
                                        <input type="text" v-model="form.name" value="" required="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" v-model="form.type" value="" class="form-control" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <label class="col-6">Pangkat Golongan</label>
                                    <label class="col-6">Jabatan Fungsional</label>
                                    <div class="col-6">
                                        <input type="text" v-model="form.pangkat" value="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-6">
                                        <input type="text" v-model="form.jabatan" value="" class="form-control" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Dosen
                                        </div>
                                    </div>
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari NIP/NIDN/Nama Dosen...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button class="btn btn-primary ml-2"  @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Data Dosen
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>NIP | NIDN</th>
                                            <th>Nama</th>
                                            <th>Jenis Kepegawaian</th>
                                            <th>Pangkat Golongan</th>
                                            <th>Jabatan Fungsional</th>
                                            <th style="width:5%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(user, index) in dataPengguna" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ user.nip }} | {{ user.nidn }}</td>
                                            <td>{{ user.name }}</td>
                                            <td>{{ user.type }}</td>
                                            <td>{{ user.pangkat }}</td>
                                            <td>{{ user.jabatan }}</td>
                                            <td>
                                            <span v-if="user.status == 1" class="badge badge-success mr-3 mb-3">Aktif</span>
                                            <span v-if="user.status == 0" class="badge badge-secondary mr-3 mb-3">Pensiun</span>
                                            </td>
                                            <td class="text-center">
                                                <button v-if="user.deleted == 0" @click.prevent="editForm(user.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit mr-2"></i>Ubah</button>
                                                <button v-if="user.deleted == 0" @click.prevent="postDelete(user.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2 mr-2"></i>Hapus</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Master Dosen'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataPengguna: [],
                dataPengutamaan: [],
                fetching: true,
                checkPassword: 0,
                form: {
                    id: '',
                    nip: '',
                    nidn: '',
                    name: '',
                    type: '',
                    pangkat: '',
                    jabatan: '',
                    status: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 7,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
            this.getPengutamaan();
        },
        components: {
            vPagination
        },
        methods: {
            show_modal() {
                this.form.id = '';
                this.form.nip = '';
                this.form.nidn = '';
                this.form.name = '';
                this.form.type = '';
                this.form.pangkat = '';
                this.form.jabatan = '';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data data periode
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/dosen', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/master/dosen/save', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data Dosen berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData()
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/dosen/detail?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.nip = response.data.data[0].nip;
                        this.form.nidn = response.data.data[0].nidn;
                        this.form.name = response.data.data[0].name;
                        this.form.type = response.data.data[0].type;
                        this.form.pangkat = response.data.data[0].pangkat;
                        this.form.jabatan = response.data.data[0].jabatan;
                        this.form.status = response.data.data[0].status;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/dosen/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data berhasil dihapus.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Data sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>